import React from "react";
import {
  Button,
  Typography,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { idToTimeDMMMYYYY, showRole } from "../utils/tool";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const ListUser = ({ item, clickDel, clickReset }) => (
  <Card sx={{ maxWidth: 345, margin: "10px" }}>
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        User: {item.username}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Role: {showRole(item.role)}
      </Typography>

      <Typography variant="body2" color="text.secondary">
        Day create : {idToTimeDMMMYYYY(item._id)}
      </Typography>
    </CardContent>
    <CardActions>
      <Button
        variant="outlined"
        size="small"
        onClick={() => clickReset(item.username)}
      >
        Reset password
      </Button>
      <Button
        variant="outlined"
        color="error"
        style={{ marginLeft: 20 }}
        // endIcon={<DeleteRoundedIcon />}
        onClick={() => clickDel(item.username)}
      >
        Delete
      </Button>
    </CardActions>
  </Card>
);

export { ListUser };
