import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Alert,
  LinearProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  API_get_accommondation,
  API_edit_accommondation,
  API_clone_accommondation,
  API_delete_accommondation,
} from "./utils/http";
import AccomInput from "./components/AccomInput";

import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { COLUMN_ACCOM } from "./utils/constDispatch";

export default function Accommodation(props) {
  const [search_text, set_search_text] = useState("");
  const [row_click, set_row_click] = useState(null);
  const [accommondation, set_accommondation] = useState(null);
  const [edit_ok, set_edit_ok] = useState(null);
  const [clone_ok, set_clone_ok] = useState(null);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [data_filter, set_data_filter] = useState(null);
  const [new_code, set_new_code] = useState(null);

  const handle_search = (search_text) => {
    if (search_text.replace(/\W/g, "").toLocaleLowerCase() == "") {
      set_data_filter(accommondation);
    } else {
      let data_tmp = accommondation.filter((x) => {
        let str = x.city + x.code + x.hotel;

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  async function get_data_accommondation() {
    API_get_accommondation((data) => {
      set_accommondation(data);
      set_data_filter(data);
    });
  }
  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };
  const handleClickOpen2 = () => {
    setOpen2(!open2);
  };

  async function edit_data_accommondation(id, data) {
    API_edit_accommondation(id, data, (data) => {
      set_edit_ok(data.success);
      handleClickOpen();
      get_data_accommondation();
    });
  }

  useEffect(() => {
    get_data_accommondation();
  }, []);

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  return (
    <Box style={{ flexDirection: "column", display: "flex" }}>
      {edit_ok != null ? (
        edit_ok == true ? (
          <Alert severity="success">Edit success</Alert>
        ) : (
          <Alert severity="error">Edit not success</Alert>
        )
      ) : null}
      {clone_ok != null ? (
        clone_ok == true ? (
          <Alert severity="success">Clone success</Alert>
        ) : (
          <Alert severity="error">Clone not success</Alert>
        )
      ) : null}

      <Typography variant="h4" style={{ marginTop: 40 }}>
        List of accommondation
      </Typography>
      <Box
        style={{
          display: "flex",
          padding: 10,
          alignItems: "center",
          position: "fo",
        }}
      >
        <TextField
          fullWidth
          style={{ margin: 5 }}
          id="search"
          label="search"
          variant="outlined"
          value={search_text}
          onChange={(e) => {
            if (e.target.value == "") {
              handle_search("");
              set_selected_id(null);
            }
            set_search_text(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              handle_search(search_text);
              set_selected_id(null);
            }
          }}
        />

        {selected_id == null ? null : (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              endIcon={<BorderColorRoundedIcon />}
              onClick={() => handleClickOpen()}
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              variant="outlined"
              endIcon={<CopyAllRoundedIcon />}
              onClick={() => handleClickOpen2()}
            >
              Clone
            </Button>
            <Button
              variant="outlined"
              color="error"
              style={{ marginLeft: 20 }}
              endIcon={<DeleteRoundedIcon />}
              onClick={() => handleClickOpen1()}
            >
              Delete
            </Button>
          </Box>
        )}
      </Box>

      <Dialog open={open} onClose={handleClickOpen}>
        <DialogTitle>Edit accommondation</DialogTitle>
        <DialogContent>
          <AccomInput
            init_data={row_click}
            onSaveClick={(id, data) => {
              edit_data_accommondation(id, data);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open2} onClose={handleClickOpen2}>
        <DialogTitle>Clone</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter new code</DialogContentText>
          <TextField
            autoFocus
            value={new_code}
            onChange={(e) => set_new_code(e.target.value)}
            margin="dense"
            id="code"
            label="code"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen2}>Close</Button>
          <Button
            onClick={() => {
              handleClickOpen2();
              API_clone_accommondation(selected_id, new_code, (e) => {
                if (e.success) {
                  get_data_accommondation();
                }
              });
            }}
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open1} onClose={handleClickOpen1}>
        <DialogTitle>Confirm deleting</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpen1}>Close</Button>
          <Button
            onClick={() => {
              handleClickOpen1();
              API_delete_accommondation(selected_id, (x) => {
                if (x.success) {
                  get_data_accommondation();
                }
              });
            }}
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>

      {accommondation == null ? (
        <Box sx={{ width: "100%", marginTop: 10 }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box sx={{ height: 700, width: "100%" }}>
          <DataGrid
            getRowId={(item) => item._id}
            rows={data_filter}
            columns={COLUMN_ACCOM}
            initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
            pageSizeOptions={[5, 10, 20]}
            rowSelectionModel={rowSelection}
            onRowSelectionModelChange={(e) => {
              setRowSelection(e);
              set_selected_id(e[0]);
              set_row_click(accommondation.find((x) => x._id === e[0]));
            }}
            disableMultipleRowSelection={true}
          />
        </Box>
      )}
    </Box>
  );
}
