import React, { useState, useEffect } from "react";
import { Tab, Tabs, Box, Button, LinearProgress } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import ListActivities from "./components/ListActivities";
import { API_edit_booking } from "./utils/http";
import { useTasks } from "./AppContext";
import InputLeftInfo from "./components/InputLeftInfo";
import InputLeftPrice from "./components/InputLeftPrice";
import ListAccom from "./components/ListAccom";

export default function InputLeft({ booking_id }) {
  const data_pdf = useTasks();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const SaveChange = () => {
    API_edit_booking(booking_id, data_pdf, (data) => {});
  };

  if (data_pdf == null) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Infomation" />
        <Tab label="Activities" />
        <Tab label="Accommodation" />
        <Tab label="Price" />
      </Tabs>

      <Box style={{ maxHeight: "90vh", overflow: "auto" }}>
        {value == 0 ? <InputLeftInfo /> : null}
        {value == 1 ? <ListActivities /> : null}
        {value == 2 ? <ListAccom /> : null}
        {value == 3 ? <InputLeftPrice /> : null}
      </Box>

      <Button
        style={{ position: "absolute", bottom: 30, right: 30 }}
        variant="contained"
        endIcon={<SaveIcon />}
        onClick={() => SaveChange()}
      >
        Saving changes...
      </Button>
    </div>
  );
}
