import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Button, LinearProgress } from "@mui/material";
import TabPDFDraft from "./components/TabPDFDraft";
import TabPDFDetail from "./components/TabPDFDetail";
import { API_get_bookings_id } from "./utils/http";
import CachedIcon from "@mui/icons-material/Cached";

export default function OutputRight({ booking_id }) {
  const [value, setValue] = useState(0);
  const [data_pdf, set_data] = useState(null);
  const [loading, set_loading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const get_data_booking = async () => {
    await API_get_bookings_id(booking_id, (x) => {
      set_data(x);
      set_loading(false);
    });
  };

  useEffect(() => {
    get_data_booking();
  }, []);

  if (loading) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 3,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Draft booking" />
          <Tab label="Detail Itinerary" />
        </Tabs>
        {value == 0 ? (
          <TabPDFDraft data_pdf={data_pdf} booking_id={booking_id} />
        ) : null}
        {value == 1 ? (
          <TabPDFDetail data_pdf={data_pdf} booking_id={booking_id} />
        ) : null}

        <Button
          style={{ position: "absolute", bottom: 100, right: 30 }}
          variant="contained"
          endIcon={<CachedIcon />}
          onClick={() => {
            get_data_booking();
          }}
        >
          Re-generate pdf file
        </Button>
      </Box>
    );
  }
}
