// const APIBASE = "http://100.110.121.44:6002/api/";
// const APIBASE = "http://107.120.133.77:6002/api/";
const APIBASE = "https://admin.svietnamadventures.com/api/";
// const APIBASE = "http://18.138.237.209:6002/api/";

const API_get_bookings = async (callback) => {
  await fetch(APIBASE + "booking", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data.data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_booking = async (id, data, callback) => {
  await fetch(APIBASE + "booking/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_get_bookings_id = async (id, callback) => {
  await fetch(APIBASE + "booking/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data.data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_delete_bookings_id = async (id, callback) => {
  await fetch(APIBASE + "booking/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_clone_bookings = async (from_id, to_booking_number, callback) => {
  await fetch(APIBASE + "booking/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      to_booking_number: to_booking_number.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_get_activities = async (callback) => {
  await fetch(APIBASE + "activities", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_activities = async (id, data, callback) => {
  await fetch(APIBASE + "activities/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_clone_activities = async (from_id, code, callback) => {
  await fetch(APIBASE + "activities/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      code: code,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};
const API_delete_activities = async (id, callback) => {
  await fetch(APIBASE + "activities/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_get_accommondation = async (callback) => {
  await fetch(APIBASE + "accommondation", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data.data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_edit_accommondation = async (id, data, callback) => {
  await fetch(APIBASE + "accommondation/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_clone_accommondation = async (from_id, code, callback) => {
  await fetch(APIBASE + "accommondation/clone", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify({
      from_id: from_id.toString(),
      code: code,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        callback(data);
      } else {
        console.log(data.error);
        callback(null);
      }
    })
    .catch((error) => {
      console.log(error);
      callback(null);
    });
};

const API_delete_accommondation = async (id, callback) => {
  await fetch(APIBASE + "accommondation/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_login = async (username, password, callback) => {
  await fetch(APIBASE + "user/login", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: username.toString(),
      password: password.toString(),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_changepassword = async (body, callback) => {
  await fetch(APIBASE + "user/", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_AddUser = async (body, callback) => {
  await fetch(APIBASE + "user/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_ResetPassword = async (username, callback) => {
  await fetch(APIBASE + "user/resetpass/" + username, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_GetUser = async (callback) => {
  await fetch(APIBASE + "user/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

const API_DelUser = async (username, callback) => {
  await fetch(APIBASE + "user/" + username, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionStorage.getItem("svntk"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
      callback(error);
    });
};

export {
  //booking
  API_get_bookings,
  API_clone_bookings,
  API_get_bookings_id,
  API_edit_booking,
  API_delete_bookings_id,
  // activities
  API_get_activities,
  API_edit_activities,
  API_clone_activities,
  API_delete_activities,
  // accommondation
  API_get_accommondation,
  API_edit_accommondation,
  API_clone_accommondation,
  API_delete_accommondation,
  //users
  API_login,
  API_changepassword,
  API_DelUser,
  API_ResetPassword,
  API_GetUser,
  API_AddUser,
};
