import React, { useState, useEffect } from "react";
import { TextField, Typography } from "@mui/material";
import App from "./App";
import { API_get_bookings_id, API_login } from "./utils/http";
import { jwtDecode } from "jwt-decode";

function Login() {
  const [password, set_password] = useState("");
  const [username, set_username] = useState("");
  const [login_status, set_login_status] = useState(false);
  const [login_text, set_login_text] = useState("");

  async function login() {
    API_login(username, password, (x) => {
      // console.log(x);

      if (x.success) {
        set_login_status(x.success);
        sessionStorage.setItem("svntk", x.token);
      } else {
        set_login_text(x.error);
      }
    });
  }

  function check_token() {
    let token = sessionStorage.getItem("svntk");
    if (token != null) {
      let decode_token = jwtDecode(token);
      let current_date = new Date();
      if (decode_token.exp * 1000 < current_date.getTime()) {
        set_login_status(false);
      } else {
        set_login_status(true);
      }
    }
  }

  useEffect(() => {
    check_token();
  }, [login_status]);

  if (login_status == false) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img src="LOGO.jpg" height={300} style={{ marginTop: 30 }} />
        <TextField
          autoFocus
          style={{ margin: 10, width: 500 }}
          id="username"
          label="username"
          variant="outlined"
          value={username}
          onChange={(e) => {
            set_username(e.target.value);
          }}
        />
        <TextField
          style={{ margin: 30, width: 500 }}
          id="password"
          label="password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => {
            set_password(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.keyCode == 13) {
              login();
            }
          }}
        />
        <Typography>{login_text}</Typography>
      </div>
    );
  } else {
    return <App />;
  }
}

export default Login;
