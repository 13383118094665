export const STATUS_CODE = {
  proceeding: "proceeding",
  dealing: "dealing",
  failed: "failed",
  sample: "sample",
  done: "done",
};

export const COLUMN_BOOKING = [
  {
    headerName: "Status",
    id: 1,
    width: 120,
    filterable: false,
    field: "status",
  },
  {
    headerName: "Booking REF",
    id: 0,
    width: 250,
    filterable: false,
    field: "booking_number",
  },
  {
    headerName: "Name",
    id: 2,
    width: 200,
    filterable: false,
    field: "customer_name",
  },
  {
    headerName: "Cell",
    id: 2,
    width: 150,
    filterable: false,
    field: "customer_cell",
  },
  {
    headerName: "Email",
    id: 3,
    width: 200,
    field: "customer_email",
    filterable: false,
  },
  {
    headerName: "Tour",
    id: 4,
    width: 300,
    filterable: false,
    field: "package",
  },
  {
    headerName: "Travel date",
    id: 5,
    width: 200,
    filterable: false,
    field: "day_start",
  },
  {
    headerName: "Created date",
    id: 6,
    width: 200,
    filterable: false,
    field: "created_at",
  },
  {
    headerName: "User create",
    id: 7,
    width: 150,
    filterable: false,
    field: "created_by",
  },
];

export const COLUMN_ACCOM = [
  {
    headerName: "code",
    id: 0,
    width: 200,
    filterable: false,
    field: "code",
  },
  {
    headerName: "city",
    id: 1,
    width: 200,
    filterable: false,
    field: "city",
  },
  {
    headerName: "hotel",
    id: 2,
    width: 300,
    filterable: false,
    field: "hotel",
  },
  {
    headerName: "type",
    id: 3,
    width: 200,
    field: "type",
    filterable: false,
  },
  {
    headerName: "status",
    id: 4,
    width: 120,
    filterable: false,
    field: "status",
  },
  {
    headerName: "remark",
    id: 5,
    width: 120,
    filterable: false,
    field: "remark",
  },
  {
    headerName: "address",
    id: 5,
    width: 300,
    filterable: false,
    field: "address",
  },
];

export const COLUMN_ITINERRARY = [
  {
    headerName: "code",
    id: 0,
    width: 400,
    filterable: false,
    field: "label",
  },
  {
    headerName: "activities",
    id: 1,
    width: 300,
    filterable: false,
    field: "activities",
  },
  {
    headerName: "Location code",
    id: 2,
    width: 200,
    filterable: false,
    field: "accomodation_code",
  },
  {
    headerName: "Pickup time",
    id: 3,
    width: 200,
    field: "pickuptime",
    filterable: false,
  },
  {
    headerName: "Transportation",
    id: 4,
    width: 200,
    filterable: false,
    field: "transportation",
  },
  {
    headerName: "Guide",
    id: 5,
    width: 200,
    filterable: false,
    field: "guide",
  },
];

export const dispatchTYPE = {
  init: "init",

  booking_number: "booking_number",
  prepared_by: "prepared_by",
  customer_name: "customer_name",
  customer_cell: "customer_cell",
  no_of_client: "no_of_client",
  check_in: "check_in",
  check_out: "check_out",
  package: "package",
  room_type: "room_type",
  tour_type: "tour_type",
  arrival_airport: "arrival_airport",
  arrival_flight: "arrival_flight",
  departure_airport: "departure_airport",
  departure_flight: "departure_flight",
  special_food_requirements: "special_food_requirements",
  tour_guide: "tour_guide",

  price_exclude: "price_exclude",
  price_include: "price_include",
  day_start: "day_start",

  customer_email: "customer_email",
  remember_to_bring: "remember_to_bring",
  note: "note",

  price_label: "price_label",
  price_price: "price_price",
  price_value: "price_value",

  activities_activities: "activities_activities",
  activities_accommondation: "activities_accommondation",
  activities_accommondation_address: "activities_accommondation_address",
  activities_accommondation_type: "activities_accommondation_type",
  activities_accommondation_location: "activities_accommondation_location",
  activities_accommondation_full: "activities_accommondation_full",
  activities_details: "activities_details",
  activities_pickuptime: "activities_pickuptime",
  activities_transportation: "activities_transportation",
  activities_guide: "activities_guide",

  activities_adding: "activities_adding",
  activities_deleting: "activities_deleting",

  accommondation_regenerate: "accommondation_regenerate",
  accommondation_adding: "accommondation_adding",
  accommondation_deleting: "accommondation_deleting",
  accommondation_hotel: "accommondation_hotel",
  accommondation_type: "accommondation_type",
  accommondation_status: "accommondation_status",
  accommondation_remark: "accommondation_remark",
  accommondation_address: "accommondation_address",

  draft_title_pdf: "draft_title_pdf",
  detail_title_pdf: "detail_title_pdf",
};
