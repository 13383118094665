import React, { useState } from "react";

import {
  Box,
  List,
  Divider,
  ListItem,
  Drawer,
  ListItemButton,
  ListItemText,
  AppBar,
} from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import InputLeft from "./InputLeft";
import OutputRight from "./OutputRight";
import Itinerary from "./Itinerary";
import ListBooking from "./ListBooking";
import { API_get_bookings_id } from "./utils/http";
import Accommodation from "./Accommondation";

import BookmarkAddRoundedIcon from "@mui/icons-material/BookmarkAddRounded";
import ExploreRoundedIcon from "@mui/icons-material/ExploreRounded";
import DriveEtaRoundedIcon from "@mui/icons-material/DriveEtaRounded";
import { styles } from "./AppStyle";
import { dispatchTYPE } from "./utils/constDispatch";
import { useTasksDispatch } from "./AppContext";
import User from "./User";

function App() {
  const BOOKING = "BOOKING";
  const ITINERARY = "ITINERARY";
  const ACCOMMODATION = "ACCOMMODATION";
  const USER = "USER";

  const [drawer_data, set_drawer_data] = useState(BOOKING);
  const [booking_id, set_booking_id] = useState(null);
  const dispatch = useTasksDispatch();

  const onClick_booking_id = (booking_id) => {
    set_booking_id(booking_id);
    API_get_bookings_id(booking_id, (x) => {
      dispatch({
        type: dispatchTYPE.init,
        data: x,
      });
    });
  };

  const showing_drawer = (state) => {
    if (state === BOOKING) {
      if (booking_id != null) {
        return <InputLeft booking_id={booking_id} />;
      } else {
        return <ListBooking onClickDetail={(x) => onClick_booking_id(x)} />;
      }
    } else if (state === ITINERARY) {
      return <Itinerary />;
    } else if (state === ACCOMMODATION) {
      return <Accommodation />;
    } else if (state === USER) {
      return <User />;
    }
  };

  const show_pdf = () => {
    if (booking_id != null && drawer_data === BOOKING) {
      return <OutputRight booking_id={booking_id} />;
    }
    return null;
  };

  function ListItemCus({ funcX, data_show, icon }) {
    return (
      <ListItem disablePadding>
        <ListItemButton onClick={funcX}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={data_show} />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <AppBar position="fixed" sx={styles.appbar}></AppBar>
      <Drawer sx={styles.drawer} variant="permanent" anchor="left">
        <List>
          <ListItemCus
            funcX={() => {
              set_booking_id(null);
              set_drawer_data(BOOKING);
            }}
            data_show="Booking"
            icon={<BookmarkAddRoundedIcon />}
          />
          <ListItemCus
            funcX={() => set_drawer_data(ITINERARY)}
            data_show="Itinerary"
            icon={<DriveEtaRoundedIcon />}
          />
          <ListItemCus
            funcX={() => set_drawer_data(ACCOMMODATION)}
            data_show="Location"
            icon={<ExploreRoundedIcon />}
          />
          <ListItemCus
            funcX={() => set_drawer_data(USER)}
            data_show="User"
            icon={<ManageAccountsRoundedIcon />}
          />
        </List>
        <Divider />
      </Drawer>
      <Box component="main" sx={styles.box1}>
        {showing_drawer(drawer_data)}
      </Box>
      {show_pdf()}
    </div>
  );
}

export default App;
