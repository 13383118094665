import React, { useState, useEffect } from "react";
import { Button, TextField, Box, Typography } from "@mui/material";
import {
  API_AddUser,
  API_DelUser,
  API_GetUser,
  API_ResetPassword,
  API_changepassword,
} from "./utils/http";
import { AddUser } from "./components/AddUser";
import { ListUser } from "./components/ListUser";

export default function User() {
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [new_password, set_new_password] = useState("");
  const [new_password_confirmation, set_new_password_confirmation] =
    useState("");

  const [user_arr, set_uer_arr] = useState(null);
  const [status, setStatus] = useState("");

  const ConfirmBtn = () => {
    if (
      username != "" &&
      password != "" &&
      new_password != "" &&
      new_password != password &&
      new_password == new_password_confirmation
    ) {
      return (
        <Button
          style={{ margin: 5, width: 400 }}
          variant="outlined"
          onClick={() => {
            API_changepassword(
              {
                username: username,
                password: password,
                newpassword: new_password,
              },
              (data) => {
                if (data.success) {
                  sessionStorage.removeItem("svntk");
                  window.location.reload();
                }
              }
            );
          }}
        >
          confirm
        </Button>
      );
    }
  };

  async function GetUser() {
    API_GetUser((data) => {
      if (data.success) {
        set_uer_arr(data.data);
      }
    });
  }

  const DeleteUser = (username) => {
    API_DelUser(username, (data) => {
      setStatus(data.message);
      if (data.success) {
        GetUser();
      }
    });
  };

  const ResetUser = (username) => {
    API_ResetPassword(username, (data) => {
      setStatus(data.message);
      if (data.success) {
        GetUser();
      }
    });
  };

  const ClickAddUser = (body) => {
    API_AddUser(body, (data) => {
      if (data.success) {
        GetUser();
      }
    });
  };

  useEffect(() => {
    GetUser();
  }, []);

  return (
    <Box style={{ flexDirection: "column", display: "flex" }}>
      <Typography variant="h5">Change password</Typography>
      <TextField
        style={{ margin: 5, width: 400 }}
        id="username"
        label="username"
        variant="outlined"
        value={username}
        onChange={(e) => set_username(e.target.value)}
      />
      <TextField
        style={{ margin: 5, width: 400 }}
        id="password"
        type="password"
        label="old password"
        variant="outlined"
        value={password}
        onChange={(e) => set_password(e.target.value)}
      />

      <TextField
        style={{ margin: 5, width: 400 }}
        id="new_password"
        type="password"
        label="new password"
        variant="outlined"
        value={new_password}
        onChange={(e) => set_new_password(e.target.value)}
      />

      <TextField
        style={{ margin: 5, width: 400 }}
        id="new_password_confirmation"
        type="password"
        label="retype new password"
        variant="outlined"
        value={new_password_confirmation}
        onChange={(e) => set_new_password_confirmation(e.target.value)}
      />

      <ConfirmBtn />

      <Typography style={{ marginTop: 10 }} variant="h5">
        List user
      </Typography>
      <Box sx={{ display: "flex" }}>
        {status == "" ? null : <Typography>{status}</Typography>}
        {user_arr == null
          ? null
          : user_arr.map((item) => (
              <ListUser
                item={item}
                clickReset={(x) => ResetUser(x)}
                clickDel={(x) => DeleteUser(x)}
              />
            ))}
      </Box>
      <Typography style={{ marginTop: 10 }} variant="h5">
        Add user
      </Typography>
      <AddUser clickAdd={(x) => ClickAddUser(x)} />
    </Box>
  );
}
