import React, { useState, useEffect } from "react";

import {
  Box,
  Typography,
  Button,
  TextField,
  Paper,
  Autocomplete,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  TableCell,
  LinearProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { API_get_accommondation } from "../utils/http";
import { useTasks, useTasksDispatch } from "../AppContext";
import { dispatchTYPE } from "../utils/constDispatch";

function ListAccom() {
  const data_pdf = useTasks();
  const dispatch = useTasksDispatch();
  const [accommondation, set_accommondation] = useState(null);
  const [value_adding, setValueAdding] = useState(null);

  async function get_data() {
    API_get_accommondation((data) => {
      set_accommondation(data);
    });
  }

  useEffect(() => {
    get_data();
  }, []);

  let list_accom_clean = [];
  let list_accom = new Set();

  try {
    if (data_pdf != null && accommondation != null) {
      data_pdf.activities.map((item) => {
        list_accom.add(item.accommondation.code);
      });

      for (const item_set of list_accom) {
        accommondation.map((item) => {
          if (
            item.code.toLowerCase().replace(/\W/g, "") ==
            item_set.toLowerCase().replace(/\W/g, "")
          ) {
            list_accom_clean.push(item);
          }
        });
      }
    }
  } catch (error) {
    console.log(error.message);
  }

  const headerRow = [
    { label: "CODE", id: 0, attr: "code", width: 10 },
    { label: "City", id: 1, attr: "city", width: 20 },
    { label: "Hotel", id: 2, attr: "hotel", width: 60 },
    { label: "Type", id: 3, attr: "type", width: 10 },
    { label: "Remark", id: 5, attr: "remark", width: 10 },
  ];

  if (accommondation == null) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box style={{ marginTop: 20, margin: 10 }}>
        <Typography variant="h4" style={{ marginBottom: 20 }}>
          Regenerated accommodation
        </Typography>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headerRow.map((item) => (
                  <TableCell>{item.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list_accom_clean.map((row) => (
                <TableRow key={row.name}>
                  {headerRow.map((rowx) => (
                    <TableCell>{row[rowx.attr]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography style={{ marginBottom: 20 }}>
          this list base on location of Activities, if click Regenerated all
          accommodation will be replaced
        </Typography>
        <Button
          style={{ margin: 10 }}
          variant="contained"
          endIcon={<SendIcon />}
          onClick={() =>
            dispatch({
              type: dispatchTYPE.accommondation_regenerate,
              value: list_accom_clean,
            })
          }
        >
          Regenerated
        </Button>

        <Divider></Divider>

        <Typography variant="h4" style={{ margin: 10 }}>
          List of accommodation
        </Typography>

        {data_pdf.accommondation.map((item, index) => (
          <Paper
            elevation={0}
            style={{
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h5">CODE: {item.code}</Typography>
              <Typography variant="h5">CITY: {item.city}</Typography>
              <Button
                onClick={() => {
                  dispatch({
                    type: dispatchTYPE.accommondation_deleting,
                    index_in_array: index,
                  });
                }}
                variant="outlined"
                color="error"
              >
                Delete {item.city}
              </Button>
            </Box>
            <TextField
              style={{ margin: 10 }}
              id="hotel"
              label="hotel"
              multiline
              variant="outlined"
              defaultValue={"TBA"}
              value={item.hotel}
              onChange={(e) => {
                dispatch({
                  type: dispatchTYPE.accommondation_hotel,
                  value: e.target.value,
                  index_in_array: index,
                });
              }}
            />
            <TextField
              style={{ margin: 10 }}
              id="address"
              label="address"
              multiline
              variant="outlined"
              defaultValue={"TBA"}
              value={item.address}
              onChange={(e) => {
                dispatch({
                  type: dispatchTYPE.accommondation_address,
                  value: e.target.value,
                  index_in_array: index,
                });
              }}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                style={{ margin: 10 }}
                id="type"
                label="type"
                variant="outlined"
                defaultValue={"TBA"}
                value={item.type}
                onChange={(e) => {
                  dispatch({
                    type: dispatchTYPE.accommondation_type,
                    value: e.target.value,
                    index_in_array: index,
                  });
                }}
              />
              <TextField
                style={{ margin: 10 }}
                id="status"
                label="status"
                variant="outlined"
                defaultValue={"TBA"}
                value={item.status}
                onChange={(e) => {
                  dispatch({
                    type: dispatchTYPE.accommondation_status,
                    value: e.target.value,
                    index_in_array: index,
                  });
                }}
              />

              <TextField
                style={{ margin: 10 }}
                id="remark"
                label="remark"
                variant="outlined"
                defaultValue={"TBA"}
                value={item.remark}
                onChange={(e) => {
                  dispatch({
                    type: dispatchTYPE.accommondation_remark,
                    value: e.target.value,
                    index_in_array: index,
                  });
                }}
              />
            </div>
          </Paper>
        ))}

        <Typography variant="h5">Adding accommodation</Typography>

        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          disableClearable
          id="combo-box-demo"
          options={accommondation}
          getOptionLabel={(option) => option.code}
          sx={{ width: 200, marginTop: 5 }}
          onChange={(e, value) => {
            setValueAdding(value);
          }}
          renderInput={(params) => (
            <TextField {...params} label="CODE" value={params.code} />
          )}
        />

        {value_adding == null ? null : (
          <Button
            variant="outlined"
            onClick={() => {
              dispatch({
                type: dispatchTYPE.accommondation_adding,
                value: accommondation[0],
              });
            }}
          >
            Add
          </Button>
        )}
      </Box>
    );
  }
}

export default ListAccom;
