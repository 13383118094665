import { StyleSheet } from "@react-pdf/renderer";
const drawerWidth = 150;

export const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    marginTop: 10,
    fontSize: 15,
    textAlign: "center",
    fontFamily: "Helvetica",
  },
  notetext: {
    marginTop: 10,
    fontSize: 10,
    textAlign: "center",
  },
  box1: {
    flexGrow: 1,
    bgcolor: "background.default",
    padding: 3,
    overflow: "auto",
    flex: 3,
  },

  appbar: {
    width: `calc(100% - ${drawerWidth}px)`,
    ml: `${drawerWidth}px`,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      boxSizing: "border-box",
    },
  },
});
