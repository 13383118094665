import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function FilterBooking({ filter_list, set_filter_list }) {
  return (
    <Box
      style={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5,
      }}
    >
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Checkbox
              checked={filter_list.proceeding}
              onChange={() => {
                let new_filter = {
                  ...filter_list,
                  proceeding: !filter_list.proceeding,
                  all: false,
                };
                set_filter_list(new_filter);
              }}
            />
          }
          label="Proceeding"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filter_list.dealing}
              onChange={() => {
                let new_filter = {
                  ...filter_list,
                  all: false,
                  dealing: !filter_list.dealing,
                };
                set_filter_list(new_filter);
              }}
            />
          }
          label="Dealing"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={filter_list.sample}
              onChange={() => {
                let new_filter = {
                  ...filter_list,
                  all: false,
                  sample: !filter_list.sample,
                };
                set_filter_list(new_filter);
              }}
            />
          }
          label="Sample"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={filter_list.done}
              onChange={() => {
                let new_filter = {
                  ...filter_list,
                  all: false,
                  done: !filter_list.done,
                };

                set_filter_list(new_filter);
              }}
            />
          }
          label="Done"
        />
      </FormGroup>

      <Box
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography style={{ marginRight: 4 }}>Travel Date</Typography>
          <DesktopDatePicker
            format="DD/MM/YYYY"
            value={dayjs(filter_list.date_from, "DD/MM/YYYY")}
            label="From"
            onChange={(e) => {
              let new_filter = { ...filter_list, date_from: e, all: false };
              set_filter_list(new_filter);
            }}
          />
          <DesktopDatePicker
            format="DD/MM/YYYY"
            value={dayjs(filter_list.date_to, "DD/MM/YYYY")}
            label="to"
            onChange={(e) => {
              let new_filter = { ...filter_list, date_to: e, all: false };
              set_filter_list(new_filter);
            }}
          />
        </LocalizationProvider>
      </Box>
      <Box>
        <Button
          variant="outlined"
          style={{ margin: 10 }}
          onClick={() => {
            let new_filter = {
              proceeding: false,
              dealing: false,
              sample: false,
              // failed: false,
              done: false,
              date_from: null,
              date_to: null,
              all: true,
            };

            set_filter_list(new_filter);
          }}
        >
          Reset filter
        </Button>
      </Box>
    </Box>
  );
}

export default FilterBooking;
