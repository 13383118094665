import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  LinearProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  API_clone_bookings,
  API_delete_bookings_id,
  API_edit_booking,
  API_get_bookings,
} from "./utils/http";

import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { idToTimeDMMMYYYY } from "./utils/tool";
import FilterBooking from "./components/FilterBooking";
import { COLUMN_BOOKING, STATUS_CODE } from "./utils/constDispatch";

function App({ props, onClickDetail }) {
  const [data, set_data] = useState(null);
  const [data_filter, set_data_filter] = useState(null);
  const [search_text, set_search_text] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [new_bookings, setNewBookings] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_status = Boolean(anchorEl);

  const [filter_list, set_filter_list] = useState({
    proceeding: true,
    dealing: true,
    sample: false,
    done: false,
    date_from: null,
    date_to: null,
    all: false,
  });

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClickOpen = () => setOpen(!open);
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };

  const get_data_booking = async () => {
    await API_get_bookings((x) => {
      let x_new = [];
      x.map((item) => {
        item.created_at = idToTimeDMMMYYYY(item._id);
        x_new.push(item);
      });
      x_new = x_new.reverse();
      set_data(x_new);

      x_new = apply_filters(x_new, filter_list);
      set_data_filter(x_new);
    });
  };

  useEffect(() => {
    get_data_booking();
  }, []);

  const handle_search = (search_text) => {
    if (search_text == "") {
      set_data_filter(data);
    } else {
      let data_tmp = data.filter((x) => {
        let str =
          x.booking_number +
          x.customer_name +
          x.customer_email +
          x.customer_cell +
          x.package +
          x.day_start +
          x.created_by;

        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const change_status_booking = async (status) => {
    let body = { status: status };

    await API_edit_booking(selected_id, body, (x) => {
      if (x.success) {
        get_data_booking();
      }
    });
  };

  const MenuStatus = (status_str) => {
    return (
      <MenuItem
        onClick={(e) => {
          handleClose(e);
          change_status_booking(status_str);
        }}
      >
        {status_str}
      </MenuItem>
    );
  };

  const apply_filters = (data, x_filter) => {
    if (x_filter.all) {
      return data;
    }

    let data_tmp = data.filter((x) => {
      if (x_filter.proceeding && x.status == STATUS_CODE.proceeding) {
        return true;
      } else if (x_filter.dealing && x.status == STATUS_CODE.dealing) {
        return true;
      } else if (x_filter.sample && x.status == STATUS_CODE.sample) {
        return true;
      } else if (x_filter.done && x.status == STATUS_CODE.done) {
        return true;
      } else if (
        !x_filter.proceeding &&
        !x_filter.dealing &&
        !x_filter.sample &&
        !x_filter.done &&
        x.status == null
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (x_filter.date_from != null) {
      data_tmp = data_tmp.filter((x) => {
        return x_filter.date_from < dayjs(x.day_start, "DD/MM/YYYY");
      });
    }

    if (x_filter.date_to != null) {
      data_tmp = data_tmp.filter((x) => {
        return dayjs(x.day_start, "DD/MM/YYYY") < x_filter.date_to;
      });
    }
    return data_tmp;
  };

  const onChangeFilter = (x_filter) => {
    let data_tmp = apply_filters(data, x_filter);
    set_data_filter(data_tmp);
  };

  return (
    <Box>
      {data_filter != null ? (
        <Box>
          {/* <Typography variant="h5" style={{ marginTop: 10 }}>
            List of booking
          </Typography> */}
          <Box style={{ display: "flex", padding: 10, alignItems: "center" }}>
            <TextField
              style={{ margin: 5 }}
              id="search"
              fullWidth
              label="search"
              variant="outlined"
              value={search_text}
              onChange={(e) => {
                if (e.target.value == "") {
                  handle_search("");
                  set_selected_id(null);
                }
                set_search_text(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  handle_search(search_text);
                  set_selected_id(null);
                }
              }}
            />
            {selected_id == null ? null : (
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Button
                  style={{ marginLeft: 20 }}
                  variant="outlined"
                  disableElevation
                  endIcon={<BorderColorRoundedIcon />}
                  onClick={(e) => {
                    handleClick(e);
                  }}
                >
                  Change_Status
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open_status}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {MenuStatus(STATUS_CODE.proceeding)}
                  {MenuStatus(STATUS_CODE.dealing)}
                  {MenuStatus(STATUS_CODE.sample)}
                  {MenuStatus(STATUS_CODE.done)}
                </Menu>

                <Button
                  style={{ marginLeft: 20 }}
                  variant="outlined"
                  endIcon={<BorderColorRoundedIcon />}
                  onClick={() => onClickDetail(selected_id)}
                >
                  Edit
                </Button>
                <Button
                  style={{ marginLeft: 20 }}
                  variant="outlined"
                  endIcon={<CopyAllRoundedIcon />}
                  onClick={() => handleClickOpen()}
                >
                  Clone
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  style={{ marginLeft: 20 }}
                  endIcon={<DeleteRoundedIcon />}
                  onClick={() => handleClickOpen1()}
                >
                  Delete
                </Button>
              </Box>
            )}
          </Box>

          <FilterBooking
            filter_list={filter_list}
            set_filter_list={(e) => {
              set_filter_list(e);
              onChangeFilter(e);
            }}
          />

          <Dialog open={open1} onClose={handleClickOpen1}>
            <DialogTitle>Confirm deleting</DialogTitle>
            <DialogContent>
              <DialogContentText>Are you sure?</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickOpen1}>Close</Button>
              <Button
                onClick={() => {
                  handleClickOpen1();
                  API_delete_bookings_id(selected_id, (x) => {
                    if (x.success) {
                      get_data_booking();
                    }
                  });
                }}
              >
                confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={open} onClose={handleClickOpen}>
            <DialogTitle>Clone booking</DialogTitle>
            <DialogContent>
              <DialogContentText>Enter new booking number</DialogContentText>
              <TextField
                autoFocus
                value={new_bookings}
                onChange={(e) => setNewBookings(e.target.value)}
                margin="dense"
                id="name"
                label="booking number"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClickOpen}>Close</Button>
              <Button
                onClick={() => {
                  handleClickOpen();
                  API_clone_bookings(selected_id, new_bookings, (e) => {
                    if (e != null) {
                      get_data_booking();
                    }
                  });
                }}
              >
                confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGrid
              getRowId={(item) => item._id}
              rows={data_filter}
              columns={COLUMN_BOOKING}
              initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
              }}
              pageSizeOptions={[5, 10, 20]}
              rowSelectionModel={rowSelection}
              onRowSelectionModelChange={(e) => {
                setRowSelection(e);
                set_selected_id(e[0]);
              }}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: 10 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
}

export default App;
