import React, { useState, useEffect } from "react";
import { TextField, Button, Autocomplete } from "@mui/material";

export default function Addinginput(props) {
  const [code, set_code] = useState(props.init_data.label);
  const [activities, set_activities] = useState(props.init_data.activities);
  const [detail, set_detail] = useState(props.init_data.detail);
  const [accomodation, set_accomodation] = useState(
    props.init_data.accomodation
  );

  const [pickuptime, set_pickuptime] = useState(props.init_data.pickuptime);
  const [transportation, set_transportation] = useState(
    props.init_data.transportation
  );
  const [guide, set_guide] = useState(props.init_data.guide);
  const [accomodation_code, set_accomodation_location] = useState(
    props.init_data.accomodation_code
  );
  const [value_adding, setValueAdding] = useState(null);

  return (
    <div style={{ flexDirection: "column", display: "flex" }}>
      <TextField
        style={{ margin: 5, flex: 1 }}
        id="code"
        label="code"
        variant="outlined"
        value={code}
        onChange={(e) => set_code(e.target.value)}
      />
      <div style={{ display: "flex" }}>
        <TextField
          style={{ margin: 5, flex: 4 }}
          id="activities"
          label="activities"
          variant="outlined"
          multiline
          minRows={3}
          value={activities}
          onChange={(e) => set_activities(e.target.value)}
        />
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.id === value.id}
          disablePortal
          disableClearable
          id="combo-box-demo"
          options={props.accom}
          getOptionLabel={(option) => option.code}
          sx={{ width: 200, marginTop: 5 }}
          defaultValue={{ code: props.init_data.accomodation_code }}
          onChange={(e, value) => setValueAdding(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Accommondation code"
              value={params.code}
            />
          )}
        />
      </div>

      <TextField
        style={{ margin: 5, flex: 3 }}
        id="activities_detail"
        label="activities detail"
        variant="outlined"
        multiline
        minRows={5}
        value={detail}
        onChange={(e) => set_detail(e.target.value)}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="pickuptime"
          label="pickup time"
          variant="outlined"
          multiline
          minRows={2}
          value={pickuptime}
          onChange={(e) => set_pickuptime(e.target.value)}
        />

        <TextField
          style={{ margin: 5, flex: 3 }}
          id="transportation"
          label="transportation"
          variant="outlined"
          value={transportation}
          onChange={(e) => set_transportation(e.target.value)}
        />
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="guide"
          label="guide"
          variant="outlined"
          value={guide}
          onChange={(e) => set_guide(e.target.value)}
        />
      </div>
      <Button
        variant="contained"
        onClick={() => {
          let data = {
            label: code,
            activities: activities,
            accomodation_code: accomodation_code,
            pickuptime: pickuptime,
            guide: guide,
            transportation: transportation,
            detail: detail,
          };

          if (value_adding != null) {
            data.accomodation_code = value_adding.code;
          }

          props.onSaveClick(props.init_data._id, data);
        }}
      >
        save
      </Button>
    </div>
  );
}
