import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Alert,
} from "@mui/material";
import {
  API_get_activities,
  API_edit_activities,
  API_clone_activities,
  API_delete_activities,
} from "./utils/http";
import { DataGrid } from "@mui/x-data-grid";
import Addinginput from "./components/AddingInput";

import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import CopyAllRoundedIcon from "@mui/icons-material/CopyAllRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { COLUMN_ITINERRARY } from "./utils/constDispatch";

export default function Itinerary(props) {
  const [search_text, set_search_text] = useState("");
  const [row_click, set_row_click] = useState(null);

  const [activities, set_activities] = useState(null);
  const [data_filter, set_data_filter] = useState(null);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [new_code, set_new_code] = useState("");
  const [edit_ok, set_edit_ok] = useState(null);
  const [clone_ok, set_clone_ok] = useState(null);
  const [accom, set_accom] = useState(null);

  async function get_data_activities() {
    API_get_activities((data) => {
      set_activities(data.data);
      set_data_filter(data.data);
      set_accom(data.accom);
    });
  }

  async function edit_data_activities(id, data) {
    API_edit_activities(id, data, (data) => {
      set_edit_ok(data.success);
      get_data_activities();
    });
  }

  const handleClickOpen = () => {
    setOpen(!open);
  };
  const handleClickOpen1 = () => {
    setOpen1(!open1);
  };
  const handleClickOpen2 = () => {
    setOpen2(!open2);
  };

  useEffect(() => {
    get_data_activities();
  }, []);

  const handle_search = (search_text) => {
    if (search_text == "") {
      set_data_filter(activities);
    } else {
      let data_tmp = activities.filter((x) => {
        let str = x.label + x.activities;
        str = str.replace(/\W/g, "").toLocaleLowerCase();
        let str_search = search_text.replace(/\W/g, "").toLocaleLowerCase();
        return str.includes(str_search);
      });

      set_data_filter(data_tmp);
    }
  };

  const [selected_id, set_selected_id] = useState(null);
  const [rowSelection, setRowSelection] = useState([]);

  const confirm_delete = () => {
    handleClickOpen1();
    API_delete_activities(selected_id, (x) => {
      if (x.success) {
        get_data_activities();
      }
    });
  };

  if (activities == null) {
    return (
      <Box sx={{ width: "100%", marginTop: 10 }}>
        <LinearProgress />
      </Box>
    );
  } else {
    return (
      <Box style={{ flexDirection: "column", display: "flex" }}>
        {edit_ok != null ? (
          edit_ok == true ? (
            <Alert severity="success">Edit success</Alert>
          ) : (
            <Alert severity="error">Edit not success</Alert>
          )
        ) : null}

        {clone_ok != null ? (
          clone_ok == true ? (
            <Alert severity="success">Clone success</Alert>
          ) : (
            <Alert severity="error">Clone not success</Alert>
          )
        ) : null}

        <Typography variant="h4" style={{ marginTop: 40 }}>
          List of Itinerary
        </Typography>
        <Box style={{ display: "flex", padding: 10, alignItems: "center" }}>
          <TextField
            style={{ margin: 5 }}
            id="search"
            fullWidth
            label="search"
            variant="outlined"
            value={search_text}
            onChange={(e) => {
              if (e.target.value == "") {
                handle_search("");
                set_selected_id(null);
              }
              set_search_text(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                handle_search(search_text);
                set_selected_id(null);
              }
            }}
          />

          {selected_id == null ? null : (
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Button
                style={{ marginLeft: 20 }}
                variant="outlined"
                endIcon={<BorderColorRoundedIcon />}
                onClick={() => handleClickOpen()}
              >
                Edit
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                variant="outlined"
                endIcon={<CopyAllRoundedIcon />}
                onClick={() => handleClickOpen2()}
              >
                Clone
              </Button>
              <Button
                variant="outlined"
                color="error"
                style={{ marginLeft: 20 }}
                endIcon={<DeleteRoundedIcon />}
                onClick={() => handleClickOpen1()}
              >
                Delete
              </Button>
            </Box>
          )}
        </Box>

        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={open}
          onClose={handleClickOpen}
        >
          <DialogTitle>Edit Itinerary</DialogTitle>
          <DialogContent>
            <Addinginput
              accom={accom}
              init_data={row_click}
              onSaveClick={(id, data) => {
                edit_data_activities(id, data);
                handleClickOpen();
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickOpen}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open1} onClose={handleClickOpen1}>
          <DialogTitle>Confirm deleting</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickOpen1}>Close</Button>
            <Button onClick={() => confirm_delete()}>confirm</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={open2} onClose={handleClickOpen2}>
          <DialogTitle>Clone</DialogTitle>
          <DialogContent>
            <DialogContentText>Enter new code</DialogContentText>
            <TextField
              autoFocus
              value={new_code}
              onChange={(e) => set_new_code(e.target.value)}
              margin="dense"
              id="code"
              label="code"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickOpen2}>Close</Button>
            <Button
              onClick={() => {
                handleClickOpen2();
                API_clone_activities(selected_id, new_code, (e) => {
                  set_clone_ok(e.success);
                  if (e.success) {
                    get_data_activities();
                  }
                });
              }}
            >
              confirm
            </Button>
          </DialogActions>
        </Dialog>

        {activities == null ? null : (
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGrid
              getRowId={(item) => item._id}
              rows={data_filter}
              columns={COLUMN_ITINERRARY}
              initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
              }}
              pageSizeOptions={[5, 10, 20]}
              rowSelectionModel={rowSelection}
              onRowSelectionModelChange={(e) => {
                setRowSelection(e);
                set_selected_id(e[0]);
                set_row_click(activities.find((x) => x._id === e[0]));
              }}
              disableMultipleRowSelection={true}
            />
          </Box>
        )}
      </Box>
    );
  }
}
