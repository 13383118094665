import React, { useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";

export default function AccomInput(props) {
  const [code, set_code] = useState(props.init_data.code);
  const [city, set_city] = useState(props.init_data.city);
  const [hotel, set_hotel] = useState(props.init_data.hotel);

  const [type, set_type] = useState(props.init_data.type);
  const [status, set_status] = useState(props.init_data.status);
  const [remark, set_remark] = useState(props.init_data.remark);
  const [address, set_address] = useState(props.init_data.address);

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
      }}
    >
      <TextField
        style={{ margin: 5, flex: 1 }}
        id="code"
        label="code"
        variant="outlined"
        value={code}
        onChange={(e) => set_code(e.target.value)}
      />
      <TextField
        style={{ margin: 5, flex: 1 }}
        id="city"
        label="city"
        variant="outlined"
        value={city}
        onChange={(e) => set_city(e.target.value)}
      />
      <TextField
        style={{ margin: 5, flex: 4 }}
        id="hotel"
        label="hotel"
        variant="outlined"
        multiline
        minRows={2}
        value={hotel}
        onChange={(e) => set_hotel(e.target.value)}
      />
      <TextField
        style={{ margin: 5, flex: 4 }}
        id="address"
        label="address"
        variant="outlined"
        value={address}
        onChange={(e) => set_address(e.target.value)}
      />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="type"
          label="type"
          variant="outlined"
          value={type}
          onChange={(e) => set_type(e.target.value)}
        />
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="status"
          label="status"
          variant="outlined"
          value={status}
          onChange={(e) => set_status(e.target.value)}
        />
        <TextField
          style={{ margin: 5, flex: 3 }}
          id="remark"
          label="remark"
          variant="outlined"
          value={remark}
          onChange={(e) => set_remark(e.target.value)}
        />
      </div>
      <Button
        variant="contained"
        onClick={() => {
          props.onSaveClick(props.init_data._id, {
            code: code,
            city: city,
            hotel: hotel,
            type: type,
            status: status,
            remark: remark,
            address: address,
          });
        }}
      >
        save
      </Button>
    </div>
  );
}
