import dayjs from "dayjs";

const showRole = (role) => {
  if (role === 100) {
    return "ADMIN";
  } else if (role === 50) {
    return "USER";
  }
};

const idToTimeDMMMYYYY = (id) => {
  let date_item = new Date(parseInt(id.substr(0, 8), 16) * 1000);
  date_item = dayjs(date_item).format("D MMM YYYY");

  return date_item;
};

export { showRole, idToTimeDMMMYYYY };
